import { render, staticRenderFns } from "./afterCounseling.vue?vue&type=template&id=6f7c42c2&scoped=true&"
import script from "./afterCounseling.vue?vue&type=script&lang=js&"
export * from "./afterCounseling.vue?vue&type=script&lang=js&"
import style0 from "./afterCounseling.vue?vue&type=style&index=0&id=6f7c42c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7c42c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VDivider,VOverlay,VProgressCircular,VTextarea})

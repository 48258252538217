<template>
  <div class="_bg-default d-flex justify-center align-center">
    <v-card
      flat
      class="radius-card card_chat d-flex justify-center"
      min-height="95vh"
      width="70%"
    >
      <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="purple"
        ></v-progress-circular>
        <b class="purple--text">Loading...</b>
      </v-overlay>
      <div style="width: 50%" class="pa-3" v-if="dataTrx">
        <p class="my-2 text-center">
          <b>Beri Penilaian untuk Psikolog</b>
        </p>
        <v-divider></v-divider>
        <div class="d-flex justify-center my-3">
          <img
            :src="
              dataTrx.psycholog.photo_profile
                ? `${env}/upload/photo_profile/${dataTrx.psycholog.id}/${dataTrx.psycholog.photo_profile}`
                : dummy
            "
            alt=""
            class="dp"
          />
        </div>
        <div>
          <b>
            <p class="text-center ma-0">
              {{ dataTrx.psycholog.nama_lengkap }}
            </p>
          </b>
          <p class="small_txt color_txt text-center">
            {{
              dataTrx.psycholog.jurusan_id == 1
                ? "Psikologi Klinis"
                : dataTrx.psycholog.jurusan_id == 2
                ? "Psikologi Industri & Organisasi"
                : "Psikologi Pendidikan"
            }}
          </p>
        </div>
        <v-divider></v-divider>

        <div class="d-flex justify-center flex-column align-center py-2">
          <p class="text-center ma-0">Rating untuk Waktu Respon</p>
          <star-rating
            :increment="0.1"
            :star-size="30"
            :show-rating="false"
            v-model="rate"
          ></star-rating>
        </div>
        <div class="d-flex justify-center flex-column align-center py-2">
          <p class="text-center ma-0">Rating untuk Kualitas Respon</p>
          <star-rating
            :increment="0.1"
            :star-size="30"
            :show-rating="false"
            v-model="time_rate"
          ></star-rating>
        </div>
        <div class="d-flex justify-center flex-column align-center py-2">
          <p class="text-center ma-0">Rating untuk Keseluruhan</p>
          <star-rating
            :increment="0.1"
            :star-size="30"
            :show-rating="false"
            v-model="quality_rate"
          ></star-rating>
        </div>
        <div class="mt-3 pa-3" style="width: 100%">
          <v-textarea
            full-width
            outlined
            auto-grow
            v-model="feedback"
            placeholder="Bagikan ulasanmu di sini. Penilaian dan ulasanmu akan bersifat anonim"
          ></v-textarea>
          <div class="d-flex justify-end">
            <v-btn color="blue" dark depressed @click="giveRate">Kirim</v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StarRating from "vue-star-rating";
export default {
  name: "afterCounseling",
  components: {
    StarRating
  },
  computed: {
    ...mapState({
      dummy: state => state.dummy,
      env: state => state.API_URL
    })
  },
  data() {
    return {
      loading: false,
      dataTrx: null,
      person: null,
      session: null,
      rate: null,
      time_rate: null,
      quality_rate: null,
      feedback: ""
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = this.$route.params.id;
      this.$store.dispatch("counseling/viewTransaction", data).then(data => {
        this.dataTrx = data[0];
        this.session = this.dataTrx.sessions.find(el => {
          return el.session == this.dataTrx.session_on;
        });
        this.loading = false;
      });
    },
    giveRate() {
      let data = {
        id: this.session.konsultasi_schedule_id,
        rating: this.rate,
        rating_waktu: this.time_rate,
        rating_kualitas: this.quality_rate,
        reflection_desc: this.feedback
      };
      this.$store.dispatch("counseling/giveRate", data).then(() => {
        this.$router.push("/public/dashboard-counseling");
      });
    }
  }
};
</script>

<style scoped>
.dp {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
